import React from 'react';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="App-logo">
          <div>Trey</div>
          <div>Dunn</div>
        </div>
        <div className="App-tagline">
          Helping you get it done.
        </div>
        <a
          className="App-link"
          href="mailto:trey@treydunn.io"
          rel="noopener noreferrer"
        >
          Contact Me
        </a>
      </header>
    </div>
  );
}

export default App;
